<template>
  <div class="container">
    <div class="row container">
      <div class="col-12 col-md-7">
        <div class="checkout-form py-5">
          <div class="container">
            <h5 class="mb-4 font-weight-bold">DETALLES DE FACTURACION </h5>
            <div class="row">
              <div class="col-12 mt-4" v-if="props.title">
                <h2 class="font-weight-bold">{{ props.title }}</h2>
              </div>
              <div class="col-12 mb-3" v-if="props.description">
                <p class="lead text-muted">{{ props.description }}</p>
              </div>
              <div class="col-12">
                <form v-on:submit.prevent="handleSubmit">
                  <SmartForm
                    v-bind:fields="fields"
                    v-bind:validations="$v.form"
                    v-bind:inputClasses="props.inputClasses"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 py-5 px-5">
        <b-card class="shadow-lg px-3">
          <h5 class="font-weight-bold">Resumen</h5>

          <p class="font-weight-bold">Plan {{ plan && plan.name }}</p>

          <div class="d-flex justify-content-between">
            <p class="mb-0 font-weight-bold">Sub-total</p>
            <p class="mb-0 ">{{plan && plan.currency}} $ {{plan && plan.price}}</p>
          </div>
          
          <hr>

          <div class="d-flex justify-content-between">
            <p class="mb-0 font-weight-bold">Total</p>
            <p class="mb-0 font-weight-bold text-primary">{{plan && plan.currency}} $ {{plan && plan.price}}</p>
          </div>

          <hr>

          <PaymentProviders v-model="form.paymentMethod" v-bind:$v="$v" />
        </b-card>

        <div class="text-right">
          <input
            type="submit"
            value="Ir a pagar"
            class="btn btn-primary px-5 mt-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import { validationMixin } from "vuelidate";
import { PETDR_CHECKOUT_FORM as defaultValidations } from "@/utils/validations";
import PaymentProviders from "@/components/checkouts/PaymentProviders.vue";
export default {
  mixins: [validationMixin, formMixin],
  components: {
    PaymentProviders
  },
  props: {
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultValidations
    };
  },
  computed: {
    plan() {
      return this.$store.state.plans.plans.find(plan => plan.id === this.$route.params.id);
    },
    defaultPaymentMethod() {
      return this.$store.state.system.configs.PAYMENT.default;
    }
  },
  watch: {
    "form": {
      deep: true,
      handler(next) {
        if (!next.paymentMethod)
          this.form.paymentMethod = this.defaultPaymentMethod;
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError)
        return;
      console.log("Ejecutar acción!");
    },
    formatToCurrency(amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  }
};
</script>